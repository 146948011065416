<template>
  <div id="user-share-data">
    <!-- 头部 -->
    <div class="user-header">
      <img src="../../../../static/img/userData/share_log.png" alt="" />
      <span @click="shareDetialToUserModel" style="margin-left: 10px"
        >{{getString(strings.User_My_Share)}}</span
      >
      <span style="margin-left: 10px">{{ initTitleAccout.name }}</span>
      <div
        v-if="!initUserShareDataNull && initCollectionContent"
        class="user-share-more"
        @click="getUserShareMind"
      >
        <span style="font-size: 14px">{{getString(strings.User_More_Message)}}</span>
        <img src="../../../../static/img/userData/look_more_mind.png" alt="" />
      </div>
    </div>

    <!-- 分类 initUserShareMore-->
    <div class="share-mind-header" v-if="initTitleAccout.accout">
      <div
        class="share-mind-and-link"
        v-for="(item, index) in shareTitle"
        :key="index"
        :class="shareTileAccout == index ? 'set-title-style' : ''"
        @click="setTitleStyle(index)"
      >
        {{ getString(item) }}
      </div>
    </div>
    <!-- 外部展示 -->
    <div class="user-share" v-if="routerName == 0">
      <div class="share-content">
        <div
          class="share-cover"
          v-for="(item, index) in initUserShareData.sharedItems"
          :key="index"
          @click="moreShareOrcollectionToLink(item)"
        >
          <div
            class="share-pc-cover"
            :style="'background-color:' + colorList[index] + ';'"
          >
            <img
              src="../../../../static/img/userData/link_logo.png"
              v-real-img="item.cover"
              alt=""
            />
          </div>
          <div class="share-text">
            <div class="share-mind-titel-look">
              <div class="share-mind-titel-text">{{item.title}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- 更多风暴 -->
    <transition name="slide-fade">
    <div class="share-potato-chips" v-if="routerName == 1">
      <div
        class="share-pc-box"
        v-for="(item, index) in initUserShareData.sharedItems"
        :key="index"
        @click="moreShareOrcollectionToLink(item)"
      >
        <div
          class="share-pc-cover"
          :style="'background-color:' + colorList[index] + ';'"
        >
          <img
            src="../../../../static/img/userData/link_logo.png"
            v-real-img="item.cover"
            alt=""
          />
        </div>
        <div class="share-pc-text">
          <div class="share-pc-title">
            <span>{{ item.title }}</span>
          </div>
          <div class="share-pc-text-content">
            <span>{{ item.subtitle }}</span>
          </div>
        </div>
      </div>
    </div>
    </transition>
    <!-- 更多链接 -->
    <transition name="slide-fade">
    <div class="share-content share-detail" v-if="routerName == 2">
      <div
        class="share-cover"
        v-for="(item, index) in initUserShareData.sharedItems"
        :key="index"
        @click="moreShareOrcollectionToLink(item)"
      >
        <div
          class="share-pc-cover"
          :style="'background-color:' + colorList[index] + ';'"
        >
          <img src="../../../../static/img/userData/link_logo.png" alt="" />
        </div>
        <div class="share-text">
          <div class="share-mind-titel">
            <div class="share-mind-titel-text">
              {{ item.title }}
            </div>
          </div>
          <div class="share-look-data">
            <div class="share-look-time">
              {{ timestampToTimeDHM(item.createTime) }}
            </div>
            <div class="share-look-count">
              <img
                src="../../../../static/img/userData/user_mind_lookcount.png"
                alt=""
              />
              {{ item.viewCount }}
            </div>
          </div>
        </div>
      </div>
    </div>
    </transition>
    <div
      :class="
        routerName == 0 ? 'user-share-data-null-page' : 'user-share-data-null'
      "
      v-if="initUserShareDataNull"
    >
      <div class="data-null">
        <img
          style="width: 120px;"
          src="../../../../static/img/userData/user_share_null_logo.png"
          alt=""
        />
        <p>{{getString(strings.User_Share_Is_Null)}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
import strings from '../../../common/lang/strings';
import getString from '../../../common/lang/language';
import {
  postUserQuerySharedItems,
  postUserQuerySharedLinks,
} from "../../../common/netWork/base_api";
import httpImageToPrefix from "../../../utils/httpImageToPrefix";
import dataTimeToFormats from "../../../utils/timestampToTime";
if (
  typeof navigator === "object" &&
  typeof navigator.userAgent === "string" &&
  navigator.userAgent.indexOf("Electron") >= 0
) {
  var { ipcRenderer } = window.require("electron");
  // var ipcRenderer = window.ipcRenderer
}
export default {
  data() {
    return {
      shareTitle: [strings.Storm, strings.User_Message_Link],
      shareTileAccout: 0,
      initUserShareData: {},
      initUserShareDataNull: false,
      initCollectionContent: false,
      //shareName: "",
      routerName: 0,
      nowElectron: false,
      colorList: [
        "#9db2c4",
        "#9179c3",
        "#e18995",
        "#ebc185",
        "#82d0dc",
        "#60a5a2",
        "#6d6a8c",
        "#f1cecc",
      ],
      strings:strings
    };
  },
  props: {
    initUserData: {
      defualt() {
        return {};
      },
    },
    initTitleAccout: {
      defualt() {
        return "";
      },
    },
  },
  created() {},
  mounted() {
    this.nowElectron = this.$tools.isElectron();
    if (this.initTitleAccout.accout) {
      this.routerName = 1;
      this.postUserQuerySharedItems();
    }
  },
  methods: {
    //语言包
    getString(i) {
      return getString(i);
    },
    openMind(item) {
      //打开导图
      ipcRenderer.send("openCalendarWindow", item);
    },
    //用户资料面板数据
    getUserShareData() {
      if (this.initUserShareData.sharedItems != null) {
        this.initUserShareDataNull = false;
        this.initCollectionContent = true;
        this.routerName == 0;
        let sharedItemsLength = this.initUserShareData.sharedItems.length;
        let sharedItems = this.initUserShareData.sharedItems;
        for (let i = 0; i < sharedItemsLength; i++) {
          var cover = sharedItems[i].cover;
          this.initUserShareData.sharedItems[i].cover = httpImageToPrefix(
            cover
          );
        }
      } else {
        this.initUserShareDataNull = true;
        this.initCollectionContent = false;
      }
    },
    //title
    setTitleStyle(index) {
      this.shareTileAccout = index;
      if (index == 0) {
        this.routerName = 1;
        this.postUserQuerySharedItems();
      } else if (index == 1) {
        this.routerName = 2;
        this.postUserQuerySharedItems();
      }
    },
    //回到初始菜单
    shareDetialToUserModel() {
      this.$emit("userShareDetailShow", true);
    },
    //菜单
    getUserShareMind() {
      this.$emit("userShareDetail", { accout: false, name: " > " + getString(strings.User_More_Message) });
    },
    //分享详情
    postUserQuerySharedItems() {
      var type;
      if (this.shareTileAccout == 0) {
        type = 2;
      } else if (this.shareTileAccout == 1) {
        type = 1;
      }
      postUserQuerySharedItems(
        {
          userId: this.userModelData.userId,
          type: type,
          lastId: "",
        },
        (shareData) => {
          this.initCollectionContent = false;
          this.initUserShareData = shareData;
          let sharedItemsLength = this.initUserShareData.sharedItems.length;
          let sharedItems = this.initUserShareData.sharedItems;
          for (let i = 0; i < sharedItemsLength; i++) {
            var cover = sharedItems[i].cover;
            this.initUserShareData.sharedItems[i].cover = httpImageToPrefix(
              cover
            );
          }
          this.myShareCount = this.initUserShareData.sharedItems.length;
          if (sharedItemsLength > 0) {
            this.initUserShareDataNull = false;
            this.shareTileAccout == 0
              ? (this.routerName = 1)
              : this.shareTileAccout == 2;
          } else {
            this.initUserShareDataNull = true;
          }
          this.addBackgroundColor(this.initUserShareData.sharedItems);
        }
      );
    },
    //时间转化
    timestampToTimeDHM(timestamp) {
      return dataTimeToFormats.timestampToTimeDHM(timestamp);
    },
    //跳转 链接1 风暴2
    moreShareOrcollectionToLink(item) {
      if (this.nowElectron) {
        if (item.type == 1) {
          this.openMind("/s/" + item.itemId);
        } else if (item.type == 2) {
          this.openMind("/c/" + item.itemId);
        }
      } else {
        if (item.type == 1) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/s/" + item.itemId,
          });
          newWindow.location = routerData.href;
        } else if (item.type == 2) {
          let newWindow = window.open();
          const routerData = this.$router.resolve({
            path: "/c/" + item.itemId,
          });
          newWindow.location = routerData.href;
        }
      }
    },
    //添加背景颜色
    addBackgroundColor(array) {
      if (array.length > this.colorList.length) {
        for (let index = 0; index < array.length; index++) {
          if (array.length != this.colorList.length) {
            this.colorList.push(this.colorList[index]);
          }
        }
      }
    },
  },
  computed: {
    ...mapState({
      userModelData: (state) => state.userModelData,
    }),
  },
  watch: {
    initUserData(newInitUserData) {
      this.initUserShareData = newInitUserData;
      this.getUserShareData();
    },
  },
};
</script>

<style lang="less" scoped>

.slide-fade-enter-active {
  transition: all 0.3s ease;
}
.slide-fade-leave-active {
  transition: all 0.1s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-fade-enter, .slide-fade-leave-to
/* .slide-fade-leave-active for below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

#user-share-data {
  .user-header {
    background: #f0f2f8;
    height: 48px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    img {
      margin: 20px 10px 20px 20px;
    }
    span {
      font-size: 18px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      color: #666666;
      opacity: 1;
      cursor: pointer;
    }
    .user-share-more {
      position: absolute;
      right: 120px;
      display: flex;
      align-items: center;
      cursor: pointer;
      overflow: hidden;
      span {
        transition: 0.3s;
      }
    }
    .user-share-more:hover {
      // span {
      //   color: #fd492b;
      //   transition: 0.3s;
      // }
      // img {
      //   width: 14px;
      //   height: 15px;
      //   filter: drop-shadow(#fd492b 100px 0);
      //   transform: translateX(-100px);
      //   overflow: hidden;
      // }
    }
  }

  .share-mind-header {
    display: flex;
    border-bottom: 3px solid #f0f2f8;
    height: 40px;
    .share-mind-and-link {
      width: 86px;
      font-size: 16px;
      height: 38px;
      font-family: Noto Sans SC;
      font-weight: 400;
      line-height: 14px;
      opacity: 1;
      text-align: center;
      cursor: pointer;
      margin: 0 30px;
    }
    .set-title-style {
      border-bottom: 3px solid #fd492b;
      color: #fd492b;
      width: 86px;
    }
  }

  .share-content {
    max-height: 228px;
    margin-top: 10px;
    display: flex;
    height: auto;
    overflow: hidden;
    .share-cover {
      box-shadow: 0px 3px 6px rgba(80, 80, 80, 0.16);
      margin: 3px 10px;
      width: 180px;
      height: 222px;
      border-radius: 4px 4px 0px 0px;
      background: rgba(0, 0, 0, 0);
      .share-pc-cover {
        width: 180px;
        height: 160px;
        overflow: hidden;
        border-radius: 4px 4px 0px 0px;
        display: flex;
        justify-content: center;
        align-items: center;
        img {
          transition: 0.5s;
        }
        img:hover {
          transform: scale(1.1);
          transition: 0.5s;
        }
      }
      cursor: pointer;
      .share-text {
        width: 180px;
        height: 62px;
        border-radius: 4px 4px 0px 0px;
        .share-mind-titel {
          margin: 10px 10px 0px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          .share-mind-titel-text {
            overflow: hidden;
            width: 146px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
          }
          .share-mind-delete {
            width: 20px;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
          }
          button {
            border: none;
            height: 20px;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
          span {
            font-size: 14px;
            font-weight: 400;
            color: #666666;
          }
        }
        .share-look-data {
          margin: 3px 10px;
          display: flex;
          justify-content: space-between;
          align-content: center;
        }
        .share-mind-titel-look {
          display: flex;
          justify-content: center;
          align-items: center;
          height: 100%;
          .share-mind-titel-text {
            overflow: hidden;
            width: 146px;
            height: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            text-align: center;
          }
        }
      }
    }
    .share-cover:hover {
      box-shadow: 5px 8px 13px rgba(80, 80, 80, 0.16);
      background-color: rgba(0, 0, 0, 0.06);
      transition: 0.5s;
      img {
        transform: scale(1.1);
        transition: 0.5s;
      }
    }
  }
  //空数据
  .user-share-data-null-page {
    display: flex;
    justify-content: center;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }
  .user-share-data-null {
    display: flex;
    justify-content: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    .data-null {
      width: 126px;
      text-align: center;
      font-size: 16px;
      font-family: Noto Sans SC;
      font-weight: 400;
      color: #cccccc;
      opacity: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
    }
  }

  .share-potato-chips {
    width: 100%;
    margin: 20px 20px;
    height: 590px;
    overflow: auto;
    .share-pc-box {
      display: flex;
      margin-top: 20px;
      .share-pc-cover {
        width: 140px;
        height: 126px;
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(0, 0, 0, 0);
        box-shadow: 0px 3px 6px #bebebe;
        opacity: 1;
        border-radius: 10px;
        cursor: pointer;
      }
      .share-pc-cover img {
        transition: 0.5s;
      }
      .share-pc-cover img:hover {
        transform: scale(1.1);
        transition: 0.5s;
      }
      .share-pc-text {
        width: 733px;
        margin-left: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        .share-pc-title {
          width: 100%;
          height: 25px;
          font-size: 18px;
          font-family: Noto Sans SC;
          /*font-weight: bold;*/
          line-height: 25px;
          color: #333333;
          opacity: 1;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 1;
            -webkit-box-orient: vertical;
            cursor: pointer;
            -webkit-transition: 0.3s;
            transition: 0.3s;
          }
        }
        .share-pc-text-content {
          width: 100%;
          height: 22px;
          font-size: 16px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 22px;
          color: #666666;
          opacity: 1;
          span {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            -webkit-transition: 0.3s;
            transition: 0.3s;
            cursor: pointer;
          }
        }
        .share-pc-bottom {
          width: 500px;
          font-size: 14px;
          font-family: Noto Sans SC;
          font-weight: 400;
          line-height: 20px;
          color: #999999;
          opacity: 1;
          display: flex;
          position: relative;
          align-items: center;
          .share-pc-time {
            position: absolute;
            left: 0;
          }
          .share-details {
            display: flex;
            justify-content: space-between;
            align-items: center;
            position: absolute;
            right: 0;
            width: 288px;
            .share-details-single {
              display: flex;
              align-items: center;
              img {
                width: 20px;
                margin-right: 10px;
              }
            }
            button {
              border: none;
              background-color: transparent;
            }
          }
        }
        .share-pc-right-detail {
          position: absolute;
          right: 30px;
          cursor: pointer;
          button {
            border: none;
            height: 20px;
            padding: 0;
            background-color: transparent;
          }
          button:hover {
            background-color: transparent;
          }
        }
      }
    }
  }
}
// ant design vue
.share-delete-button {
  cursor: pointer;
}
.ant-popover-inner-content {
  padding: 18px 23px;
}
.share-delete-content {
  display: flex;
  align-items: center;
  height: 40px;
  width: 146px;
  cursor: pointer;
  span {
    margin-left: 10px;
  }
}
</style>